import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BundlesParentWrapper,
  BundlesWrapper,
  ButtonWrapper,
  Checked,
  DateAndTimeDescription,
  DateAndTimeDescriptionWrapper,
  SubWrapper,
  Wrapper,
} from "src/components/molecules/DateAndTime/styledComponents";
import BundleComponent from "src/components/molecules/BundleComponent";
import NextStepButton from "src/components/atoms/NextStepButton";
import PenComponent from "src/components/molecules/PenComponent";
import PenContent from "src/components/molecules/DateAndTime/components/PenContent";
import FullScreenTicketButton from "src/components/atoms/FullScreenTicketButton";
import { useGetSlots } from "src/core/queries/useGetSlots";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/core/redux/store";
import { Loc } from "src/core/types/loc";
import {
  IBundle,
  IPreReservationResponse,
  IPrioritySlot,
  ISlot,
  ITour,
  SlotKey,
  SlotState,
} from "src/core/types/bundles";
import { preparePreReservationData } from "src/core/utils/preparePreReservationData";
import { usePostPreReservation } from "src/core/queries/usePostPreReservation";
import { setReservationId } from "src/core/redux/actions/reservationActions";
import { CheckOutStep, Tab } from "src/core/types";
import { setStepToPen, setToNextStep } from "src/core/utils";
import { setGlobalTimeSlots } from "src/core/redux/actions/timeSlotAction";
import { getFormattedDate } from "src/core/helpers/getFormattedDate";
import { setCapacity } from "src/core/redux/actions/capacityAction";
import { setTour } from "src/core/redux/actions/toursAction";

import checkIcon from "../../../assets/white_checked.svg";
import timeIcon from "src/assets/purchaseIcons/hodiny_cervene.png";

import { setSelectedDate as setGlobalDate } from "src/core/redux/actions/reservationDataActions";
import { useGetRemoveReservation } from "src/core/queries/useGetRemoveReservation";
import { CircularProgress } from "@mui/material";
import { setSelectedBundle } from "src/core/redux/actions/bundleActions";

interface IDateAndTimeProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  step: CheckOutStep;
  panels: CheckOutStep[];
  tabs: Tab[];
  shouldBePensShown: boolean;
}

const DateAndTime = ({
  updateCheckOutState,
  step,
  panels,
  tabs,
  shouldBePensShown,
}: IDateAndTimeProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const reservationId = useSelector((state: RootState) => state.reservationID?.reservationID);
  const selectedGlobalSlots = useSelector((state: RootState) => state.timeSlot);
  const selectedTours = useSelector((state: RootState) => state.selectedTours);
  const selectedBundle = useSelector((state: RootState) => state.bundle);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState<boolean>(window.innerWidth <= 900);
  const [selectedDate] = useState<Date>(new Date());
  const [selectedSlots, setSelectedSlots] = useState<SlotState>({} as SlotState);

  const deleteSlot = (tour: SlotKey) => {
    const currentTourPriority = selectedSlots[tour].priority;

    const filteredSlots = Object.entries(selectedSlots)
      .filter(([, slot]) => slot.priority < currentTourPriority)
      .reduce((acc, [key, slot]) => {
        acc[key as SlotKey] = slot;
        return acc;
      }, {} as SlotState);

    setSelectedSlots(filteredSlots);
  };

  const getIsSlotAvailable = (slot: IPrioritySlot, i: number) => {
    if (selectedBundle && selectedBundle.tours.length === 1) return true;
    const currentTourPriority = slot.priority;
    const foundSlot = Object.entries(selectedSlots).find(
      ([, selectedSlot]) => selectedSlot.priority < currentTourPriority,
    );
    return !!foundSlot || i === 0;
  };

  const getParentSlot = (slot: IPrioritySlot) => {
    const currentTourPriority = slot.priority;

    if (!selectedSlots || Object.keys(selectedSlots).length === 0) {
      return undefined;
    }

    const parentEntry = Object.entries(selectedSlots).find(
      ([, selectedSlot]) => selectedSlot.priority < currentTourPriority,
    );

    return parentEntry ? parentEntry[1] : undefined;
  };

  const resetSlot = () => {
    setSelectedSlots({} as SlotState);
  };

  const goToPreviousStep = () => {
    const { newTabs, newPanels } = setStepToPen({
      tabs: tabs,
      panels: panels,
      currentKey: Number(step.key),
    });
    updateCheckOutState(newTabs, newPanels);
  };

  const goToNextStep = () => {
    const { newTabs, newPanels } = setToNextStep({
      tabs: tabs,
      panels: panels,
      currentKey: Number(step.key),
    });
    updateCheckOutState(newTabs, newPanels);
  };

  const onSuccess = (data: IPreReservationResponse) => {
    dispatch(setReservationId(data.reservation));
    dispatch(setCapacity(data.capacity));
    dispatch(setGlobalDate(selectedDate));
    dispatch(setGlobalTimeSlots(selectedSlots));
    sessionStorage.setItem("current_reservation_id", data.reservation);
    goToNextStep();
  };

  //queries
  const { data } = useGetSlots({ date: selectedDate, pos: isTablet ? "kustod" : "kostnice" });
  const { mutate } = usePostPreReservation({ onSuccess: onSuccess });
  const { refetch } = useGetRemoveReservation({ reservationId: reservationId });

  const selectSlot = (slot: ISlot, tour: SlotKey) => {
    setSelectedSlots((prevState) => ({
      ...prevState,
      [tour]: { ...slot, priority: data[tour].priority },
    }));
  };

  const getIsSlotSelected = (tour: SlotKey): boolean => {
    if (data && data[tour]) {
      return data[tour].slots.some((slot: ISlot) => slot.id === selectedSlots[tour]?.id);
    }
    return false;
  };

  const isAllSelected = selectedBundle?.tours.length === Object.keys(selectedSlots).length;
  const isCheckIcon = selectedBundle?.tours.length > 1;

  const invokeReservation = () => {
    const preparedData = preparePreReservationData({
      date: getFormattedDate(selectedDate),
      reservation: null,
      pos: isTablet ? "kustod" : "kostnice",
      slots: selectedSlots,
    });
    mutate({ transformedData: preparedData });
  };

  const handlePreReservation = async () => {
    if (reservationId && JSON.stringify(selectedGlobalSlots) !== JSON.stringify(selectedSlots)) {
      await refetch();
      invokeReservation();
    } else if (
      reservationId &&
      JSON.stringify(selectedGlobalSlots) === JSON.stringify(selectedSlots)
    ) {
      const { newTabs, newPanels } = setToNextStep({
        tabs: tabs,
        panels: panels,
        currentKey: Number(step.key),
      });
      updateCheckOutState(newTabs, newPanels);
    } else {
      invokeReservation();
    }
  };

  const getTourName = (tour: SlotKey): string => {
    if (selectedTours) {
      return selectedTours[tour].title[loc];
    }
    return "";
  };

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
    setIsTablet(window.innerWidth <= 900);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    resetSlot();
  }, [selectedDate]);

  useEffect(() => {
    if (data) {
      dispatch(setTour(data));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const selectedLocalBundle = localStorage.getItem("selectedBundle");
    if (selectedLocalBundle) {
      const parsedBundle = JSON.parse(selectedLocalBundle) as IBundle;
      dispatch(setSelectedBundle(parsedBundle));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return step.isEnabled ? (
    <Wrapper>
      <SubWrapper>
        <BundlesParentWrapper>
          <DateAndTimeDescriptionWrapper>
            <img src={timeIcon} alt="" />
            <DateAndTimeDescription>{t("prefer_title_time")}</DateAndTimeDescription>
          </DateAndTimeDescriptionWrapper>
          <BundlesWrapper isSelectedTwo={Object.keys(selectedSlots).length > 1}>
            {isAllSelected && isCheckIcon && (
              <Checked>
                <img src={checkIcon} alt="check_icon" />
              </Checked>
            )}
            {!data && <CircularProgress color={"error"} />}
            {data &&
              selectedBundle &&
              selectedBundle.tours.map((tour: ITour, i: number) => (
                <BundleComponent
                  key={tour.name}
                  tour={tour.name}
                  selectSlot={selectSlot}
                  isSelected={getIsSlotSelected(tour.name)}
                  bundle={data ? data[tour.name] : undefined}
                  selectedSlot={selectedSlots[tour.name] || undefined}
                  is_alone={Object.keys(selectedSlots).length === 1}
                  deleteSlot={deleteSlot}
                  parentSlot={getParentSlot(data[tour.name]) || undefined}
                  isAvailable={getIsSlotAvailable(data[tour.name], i)}
                  loc={loc}
                />
              ))}
          </BundlesWrapper>
        </BundlesParentWrapper>
      </SubWrapper>
      {isAllSelected &&
        (isMobile ? (
          <FullScreenTicketButton
            nextStep={t("ticket_select")}
            onClick={handlePreReservation}
            shouldShowInfo={false}
          />
        ) : (
          <ButtonWrapper>
            <NextStepButton text={t("ticket_select")} handler={handlePreReservation} />
          </ButtonWrapper>
        ))}
    </Wrapper>
  ) : step.isPen && shouldBePensShown ? (
    <PenComponent prevStep={goToPreviousStep}>
      <PenContent slots={selectedSlots} date={selectedDate} loc={loc} getTourName={getTourName} />
    </PenComponent>
  ) : (
    <div></div>
  );
};

export default DateAndTime;
