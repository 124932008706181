import styled from "styled-components";

interface IBudnlesProps {
  isSelectedTwo: boolean;
}

export const Wrapper = styled.div`
  max-width: 1360px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 48px 10px;
  background: #f8f8f8;
  border-radius: 10px;
  width: 100%;

  @media (max-width: 768px) {
    margin: 220px 8px 100px 8px;
    padding: 16px 8px;
    width: unset;
  }
`;

export const SubWrapper = styled.div`
  max-width: 1360px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  border-radius: 10px;
  gap: 30px;

  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    width: unset;
  }
`;

export const CalendarWrapper = styled.div``;

export const DateAndTimeDescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export const DateAndTimeDescription = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062762;
`;

export const BundlesParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BundlesWrapper = styled.div<IBudnlesProps>`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  gap: ${(props) => (props.isSelectedTwo ? "0" : "25px")};
  @media (max-width: 768px) {
    min-height: unset;
  }
`;

export const Checked = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 73px;
  left: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #58bc51;
  z-index: 4;
  @media (max-width: 768px) {
    top: 160px;
    left: -12px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 220px;
  margin: auto 15% 15px 65%;
  cursor: pointer;
`;

export const BundleTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
`;

export const RedLine = styled.div`
  width: 1px;
  height: 1113px;
  border-right: 1px solid red;
`;
