import React, { forwardRef, useEffect, useState } from "react";
import DateAndTime from "src/components/molecules/DateAndTime";
import { Panel } from "src/components/molecules/Panel";
import { CheckOutStep, Tab } from "src/core/types";
import style_css from "./styles.module.css";

import colorCalendar from "../../../assets/kalendar_cerveny.svg";
import blueCalendar from "../../../assets/calendar_bluee.svg";

import colorPeople from "../../../assets/ticket_color.svg";
import grayPeople from "../../../assets/ticket_gray.svg";
import bluePeople from "../../../assets/ticket_blue.svg";

import colorPayment from "../../../assets/payment_color.svg";
import grayPayment from "../../../assets/payment_gray.svg";
import bluePayment from "../../../assets/payment_blue.svg";

import grayDone from "../../../assets/done_gray.svg";
import colorDone from "../../../assets/done_color.svg";

import { useTranslation } from "react-i18next";
import { style } from "./style";
import { Box } from "@mui/material";
import AmountOfPeople from "src/components/molecules/AmountOfPeople";
import { RecapPayment } from "src/components/molecules/RecapPayment";
import { useDispatch } from "react-redux";
import { resetGlobalTimeSlots } from "src/core/redux/actions/timeSlotAction";
import { PaymentConfirmation } from "src/components/molecules/PaymentConfirmation";
export interface ICheckOutState {
  tabs: Tab[];
  panels: CheckOutStep[];
}

interface ICheckOutProps {
  setTimer: (value: boolean) => void;
}

const CheckOut = forwardRef<HTMLDivElement, ICheckOutProps>(({ setTimer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [shouldBePensShown, setShouldBePensShown] = useState<boolean>(true);

  const [checkOutState, setCheckOutState] = useState<ICheckOutState>({
    tabs: [
      {
        title: t("data_time_chosen"),
        icon: blueCalendar,
        activeIcon: colorCalendar,
        doneIcon: blueCalendar,
        isTabActive: true,
        isTabDone: false,
        key: "1",
      },
      {
        title: t("amount_of_people"),
        icon: grayPeople,
        activeIcon: colorPeople,
        doneIcon: bluePeople,
        isTabActive: false,
        isTabDone: false,
        key: "2",
      },
      {
        title: t("recap_and_payment"),
        icon: grayPayment,
        activeIcon: colorPayment,
        doneIcon: bluePayment,
        isTabActive: false,
        isTabDone: false,
        key: "3",
      },
      {
        title: t("confirmation"),
        icon: grayDone,
        activeIcon: colorDone,
        doneIcon: grayDone,
        isTabActive: false,
        isTabDone: false,
        key: "4",
      },
    ],
    panels: [
      {
        description: "Panel",
        isEnabled: false,
        key: "0",
        component: Panel,
        isStepFinished: false,
        isPen: false,
      },
      {
        description: "Výběr data a času",
        isEnabled: true,
        key: "1",
        component: DateAndTime,
        isStepFinished: false,
        isPen: false,
      },
      {
        description: "Výběr data a času",
        isEnabled: false,
        key: "2",
        component: AmountOfPeople,
        isStepFinished: false,
        isPen: false,
      },
      {
        description: "Rekapitulace a platba",
        isEnabled: false,
        key: "3",
        component: RecapPayment,
        isStepFinished: false,
        isPen: false,
      },
      {
        description: "Potvrzení",
        isEnabled: false,
        key: "4",
        component: PaymentConfirmation,
        isStepFinished: false,
        isPen: false,
      },
    ],
  });

  const updateCheckOutState = (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => {
    setCheckOutState((prevState) => {
      return {
        ...prevState,
        tabs: newTabsState,
        panels: newPanelsState,
      };
    });
  };

  const updateIsPaymentEnabled = (): boolean => {
    const enabledPanelKey = checkOutState.panels.find((panel) => panel.isEnabled);
    return enabledPanelKey?.key !== "4";
  };
  useEffect(() => {
    setShouldBePensShown(updateIsPaymentEnabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkOutState]);

  useEffect(() => {
    return () => {
      dispatch(resetGlobalTimeSlots());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box className={style_css.box}>
      {checkOutState?.panels?.map((step) => {
        const Component = step.component;
        return (
          <Component
            setTimer={setTimer}
            style={style.component}
            key={step.key}
            step={step}
            shouldBePensShown={shouldBePensShown}
            tabs={checkOutState.tabs}
            panels={checkOutState.panels}
            updateCheckOutState={updateCheckOutState}
            checkOutState={checkOutState}
          />
        );
      })}
    </Box>
  );
});
CheckOut.displayName = "CheckOut";
export default CheckOut;
