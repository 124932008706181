import React, { SetStateAction } from "react";
import { paymentTypes } from "src/core/lists/paymentTypes";
import {
  RadioLabel,
  RadioTitle,
  RadioWrapper,
} from "src/components/molecules/RecapPayment/components/RadioComponent/styledComponents";
import { Radio } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RadioType } from "core/types/radioTypes";

export const RadioComponent = ({
  radioState,
  setRadioState,
}: {
  radioState: RadioType;
  setRadioState: React.Dispatch<SetStateAction<RadioType>>;
}) => {
  const { t } = useTranslation();
  return (
    <>
      {paymentTypes.map((type) => (
        <RadioWrapper key={type.id}>
          <RadioTitle>{t("customer_is_pay_by")}</RadioTitle>
          <Radio
            value={type.id}
            checked={radioState === type.id}
            onChange={() => setRadioState(type.id)}
          />
          <RadioLabel isActive={radioState === type.id}>{`${t(type.label)}`}</RadioLabel>
        </RadioWrapper>
      ))}
    </>
  );
};
