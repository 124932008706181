import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { HOMEPAGE_ROUTE, LOGIN_ROUTE } from "src/core/routing";
import { MainPage } from "./MainPage";
import { LoginPage } from "./PageLogin";

const style = {
  wrapper: {
    margin: "auto",
    overflow: "hidden",
  },
};

const Main = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const savedLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    setIsLoggedIn(savedLoggedIn);
  }, []);

  return (
    <Box style={style.wrapper}>
      <Routes>
        <Route
          path="/"
          element={<Navigate replace to={isLoggedIn ? HOMEPAGE_ROUTE : LOGIN_ROUTE} />}
        />
        <Route path={LOGIN_ROUTE} element={<LoginPage setIsLoggedIn={setIsLoggedIn} />} />
        {isLoggedIn && <Route path={HOMEPAGE_ROUTE} element={<MainPage />} />}
        <Route path="*" element={<div>404 Not Found</div>} />
      </Routes>
    </Box>
  );
};

export default Main;
