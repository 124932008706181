import { Chip, Typography } from "@mui/material";
import React from "react";

import {
  Wrapper,
  Image,
  ItemsWrapper,
  Price,
  Item,
  GreyLine,
  DiscountWrapper,
  DiscountText,
  DiscountPercent,
  PriceSubWrapper,
  PriceWrapper,
  PriceText,
  SelectButton,
  AdditionalPrice,
  SecondPartWrapper,
} from "../Panel/styledComponents";

import { IBundle, IItem } from "src/core/types/bundles";
import { useTranslation } from "react-i18next";
import { ItemText } from "src/components/pages/PageSetTypeOfTicket/components/PanelRow/styledComponent";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loc } from "src/core/types/loc";

import { PRE_RESERVATION_ROUTE } from "src/core/routing";
import { styles } from "./styles";
import { Section } from "src/components/molecules/Section";
import { setSelectedBundle } from "src/core/redux/actions/bundleActions";

const Panel = ({ bundle, loc }: { bundle: IBundle; loc: Loc }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectPanel = () => {
    const bundleWithSortedTours = {
      ...bundle,
      tours: bundle.tours.sort((a, b) => a.priority - b.priority),
      items: bundle.items.sort((a, b) => a.position - b.position),
    };
    localStorage.setItem("selectedBundle", JSON.stringify(bundleWithSortedTours));
    dispatch(setSelectedBundle(bundleWithSortedTours));
    navigate(PRE_RESERVATION_ROUTE);
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { name, image_url, items, discount, guide_price, is_favorite, note } = bundle;

  const locNote = note[loc];

  const getFullPrice = (): number => {
    const price = items.reduce((acc, curr) => acc + curr.price, 0);
    if (discount) {
      return Math.round(price * (1 - discount / 100));
    }
    return price;
  };

  return (
    <Wrapper>
      {is_favorite && (
        <Chip label={t("favourite")} color="primary" size="medium" sx={styles.chip} />
      )}

      <Image src={image_url} />
      <ItemsWrapper>
        {bundle &&
          items.map((item: IItem) => (
            <Item key={item.name[loc]}>
              <Section key={item.name[loc]} text={item.name[loc]} TextComponent={ItemText} />
              <Price>{`${item.price}${t("cz_currency")}`}</Price>
            </Item>
          ))}
        {locNote && (
          <Typography variant="body2" component="div" color="#000">
            {locNote}
          </Typography>
        )}
      </ItemsWrapper>
      <GreyLine />
      <SecondPartWrapper>
        {discount && (
          <DiscountWrapper>
            <DiscountText>{t("sale")}</DiscountText>
            <DiscountPercent>{`${discount} %`}</DiscountPercent>
          </DiscountWrapper>
        )}
        <PriceWrapper>
          <PriceSubWrapper>
            <PriceText>{`${getFullPrice()} ${t("cz_currency")}`}</PriceText>
            {guide_price && (
              <AdditionalPrice
                dangerouslySetInnerHTML={{
                  __html: `Cena s pruvodcem  <br /> ${guide_price} ${t("cz_currency")}`,
                }}
              />
            )}
          </PriceSubWrapper>
          <SelectButton onClick={selectPanel}>Zvolit</SelectButton>
        </PriceWrapper>
      </SecondPartWrapper>
    </Wrapper>
  );
};

export default Panel;
