import dayjs from "dayjs";

export const getFormattedDateToText = (value: Date | undefined, format?: string) => {
  if (value && !format) {
    return dayjs(value).format("DD. MM. YY");
  }
  if (value && format) {
    return dayjs(value).format(format);
  }
  return "";
};
