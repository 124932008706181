import { Action, DiscountPayload, DiscountType } from "src/core/types/reduxTypes";
import { PaymentDiscount } from "core/types/discounts";

const initialState: PaymentDiscount = {} as PaymentDiscount;

export const DiscountReducer = (
  //eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: Action<DiscountType, DiscountPayload>,
) => {
  switch (action.type) {
    case "SET_DISCOUNT_G":
      return { ...state, ...action.payload };
    case "RESET_DISCOUNT":
      return initialState;
    default:
      return state;
  }
};
