import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import {
  BUNDLE_ROUTE,
  FINANTIAL_CLOSURE_ROUTE,
  PRE_RESERVATION_ROUTE,
  TICKET_TYPE_ROUTE,
} from "src/core/routing";
import { PagePurchase } from "../PagePurchase";
import { PageTicketType } from "../PageTicketType";
import React from "react";
import { PageFinantialClosure } from "../PageFinantialClosure";
import PageSetTypeOfTicket from "src/components/pages/PageSetTypeOfTicket";

export const MainPage: React.FC = () => {
  return (
    <Box>
      <Routes>
        <Route path={TICKET_TYPE_ROUTE} element={<PageTicketType />} />
        <Route path={BUNDLE_ROUTE} element={<PageSetTypeOfTicket />} />
        <Route path={PRE_RESERVATION_ROUTE} element={<PagePurchase />} />
        <Route path={FINANTIAL_CLOSURE_ROUTE} element={<PageFinantialClosure />} />
      </Routes>
    </Box>
  );
};
