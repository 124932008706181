import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  margin: 0 auto;
`;

export const CheckBoxTitle = styled.span`
  margin-right: 30px;
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062762;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
`;

export const CheckBoxComponentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CheckBoxLabel = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;
