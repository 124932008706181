import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 25px;
  background: #f8f8f8;
  color: black;
`;

export const ConfirmButton = styled.div`
  width: 100%;
  max-width: 355px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  border-radius: 30px;
  gap: 20px;
  color: #ffffff;
  background: #e31340;
  cursor: pointer;
`;

export const TicketsButton = styled.div`
  width: 100%;
  max-width: 352px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding: 20px 19px;
  border-radius: 35px;
  border: 1px solid #e31340;
  color: #e31340;
  background: #ffffff;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

export const Title = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062762;
`;

export const TicketsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;
