import { SlotState } from "core/types/bundles";

export const setGlobalTimeSlots = (timeSlot: SlotState) => {
  return {
    type: "SET_TIMESLOT",
    payload: timeSlot,
  };
};

export const resetGlobalTimeSlots = () => {
  return {
    type: "RESET_TIMESLOT",
  };
};
