import React from "react";

import {
  Button,
  ButtonsWrapper,
  Description,
  Wrapper,
} from "src/components/molecules/RecapPayment/components/ConfirmationPen/styledComponents";
import { useTranslation } from "react-i18next";

import crossButton from "../../../../../assets/cross.svg";
import confirmButton from "../../../../../assets/checkmark_big.svg";

export const ConfirmationPen = ({
  onSubmit,
  onDecline,
}: {
  onSubmit: () => void;
  onDecline: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Description>{t("payment_confirmation")}</Description>
      <ButtonsWrapper>
        <Button src={crossButton} alt="crossButton" onClick={onDecline} />
        <Button src={confirmButton} alt="confirmButton" onClick={onSubmit} />
      </ButtonsWrapper>
    </Wrapper>
  );
};
