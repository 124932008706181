import { Action, SlotType } from "src/core/types/reduxTypes";
import { SlotState } from "core/types/bundles";

const initialState: SlotState = {} as SlotState;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const TimeSlotReducer = (state = initialState, action: Action<SlotType, SlotState>) => {
  switch (action.type) {
    case "SET_TIMESLOT":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET_TIMESLOT":
      return initialState;
    default:
      return state;
  }
};
