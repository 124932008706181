import styled from "styled-components";

export const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  gap: 16px;
`;

export const SectionText = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.94px;
  text-align: left;
  color: #000;
  background: #ffffff;

  @media (max-width: 768px) {
    font-family: Montserrat, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
  }
`;

export const Circle = styled.div`
  width: 12px;
  height: 12px;
  background: #e31340;
  border-radius: 50%;
  margin-top: 3px;
`;
