import React from "react";
import {
  Circle,
  SectionText,
  SectionWrapper,
} from "src/components/molecules/Section/styledComponents";

export const Section = ({
  text,
  TextComponent,
  TextAsNode,
}: {
  text: string;
  TextComponent?: React.ElementType;
  TextAsNode?: React.ReactNode;
}) => (
  <SectionWrapper>
    <div>
      <Circle />
    </div>
    {TextComponent ? (
      <TextComponent>{TextAsNode || text}</TextComponent>
    ) : (
      <SectionText>{TextAsNode || text}</SectionText>
    )}
  </SectionWrapper>
);
