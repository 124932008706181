import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 425px;
  height: 50px;
  display: flex;
  border-radius: 25px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  background: #2f4d82;
`;

export const Description = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Button = styled.img`
  cursor: pointer;
`;
