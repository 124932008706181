import { Box, Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import timerIcon from "../../../assets/timer.svg";
import timerBigIcon from "../../../assets/timerBig.svg";
import { styles } from "./styles";
import { clearFormValues, useFormValuesContext } from "src/core/context";
import { useGetRemoveReservation } from "src/core/queries/useGetRemoveReservation";
import { useLocation, useNavigate } from "react-router-dom";
import { HOMEPAGE_ROUTE } from "src/core/routing";
import { resetTicket } from "src/core/redux/actions/selectTicket";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/core/redux/store";
import styless from "./styles.module.css";

export const CountdownTimer: React.FC = () => {
  const { formValues, setFormValues } = useFormValuesContext();
  const reservationId = useSelector((state: RootState) => state.reservationID?.reservationID);
  const isApp = sessionStorage.getItem("isApp");
  const location = useLocation();
  const { data: timerData } = useGetRemoveReservation({ reservationId: reservationId });
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMiddle, setIsMiddle] = useState<boolean>(
    window.innerWidth > 768 && window.innerWidth < 1900,
  );
  const [isExtraSmall, setIsExtraSMall] = useState(window.innerWidth <= 385);

  const dipatch = useDispatch();
  const [hidden, setHidden] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(15);
  const [timerEnded, setTimerEnded] = useState<boolean>(false);
  const currentLoc = sessionStorage.getItem("loc") || "cs";

  const navigate = useNavigate();
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
    setIsMiddle(window.innerWidth > 768 && window.innerWidth < 1900);
    setIsExtraSMall(window.innerWidth <= 385);
  };
  const navigateToHome = () => {
    navigate(HOMEPAGE_ROUTE);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const getStyle = (): Record<string, string> => {
    if (isExtraSmall) {
      return styles.boxExtraSmall;
    } else if (isMiddle) {
      return styles.middleBox;
    } else {
      switch (location.pathname) {
        case "/travel":
          return styles.relative;
        default:
          if (isApp) {
            return styles.box_app;
          }
          return styles.box;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    if (minutes === 0 && seconds === 0) {
      setTimerEnded(true);
      navigateToHome();
      setFormValues(clearFormValues);
      sessionStorage.clear();
      sessionStorage.setItem("loc", currentLoc);
      dipatch(resetTicket());
      navigateToHome();
      return;
    }
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => (prevSeconds === 0 ? 59 : prevSeconds - 1));
      if (seconds === 0) {
        setMinutes((prevMinutes) => prevMinutes - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, seconds]);

  useEffect(() => {
    if (timerEnded) {
      if (timerData?.status === "OK") {
        setFormValues(clearFormValues);
        sessionStorage.clear();
        dipatch(resetTicket());
        navigateToHome();
      } else if (timerData?.status === "ERROR") {
        console.error("Timer Error:", timerData.message);
      }
      setHidden(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerEnded, timerData, setFormValues]);

  const formatTime = (value: number) => (value < 10 ? `0${value}` : value.toString());
  const buttonStyle = isMobile ? styles.button : styles.desktopButton;
  const timerNoteStyle = isMobile ? styles.timerNote : styles.timerNoteDesktop;

  return (
    <Box sx={getStyle()}>
      {!hidden && (
        <Button
          style={{
            ...buttonStyle,
            textDecoration: "none",
          }}
        >
          <Box sx={styles.div}>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
              {isMobile ? (
                <div>
                  <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                    <img alt="timerIcon" src={timerIcon} />
                    <h1
                      style={{
                        width: "100%",
                        padding: "0px",
                        margin: "0 0 0 5px",
                        fontSize: "22px",
                      }}
                    >
                      {formatTime(minutes)}:{formatTime(seconds)}
                    </h1>
                  </div>
                  <Typography
                    sx={timerNoteStyle}
                    dangerouslySetInnerHTML={{ __html: t("timer_sec_purchase") }}
                  />
                </div>
              ) : (
                <div className={styless.wrapper}>
                  <div className={styless.iconWrapper}>
                    <img alt="timerBigIcon" src={timerBigIcon} />
                  </div>
                  <div className={styless.content_wrapper}>
                    <h1 className={styless.timer}>
                      {formatTime(minutes)}:{formatTime(seconds)}
                    </h1>
                    <Typography
                      sx={timerNoteStyle}
                      dangerouslySetInnerHTML={{ __html: t("timer_purchase") }}
                    />
                  </div>
                </div>
              )}
            </div>
          </Box>
        </Button>
      )}
    </Box>
  );
};
