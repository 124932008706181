import React from "react";
import { Wrapper, InsideWrapper } from "src/components/pages/PageSetTypeOfTicket/styledComponents";
import PanelRow from "src/components/pages/PageSetTypeOfTicket/components/PanelRow";
import { useGetBundles } from "src/core/queries/useGetBundles";
import { CircularProgress } from "@mui/material";
import { Loc } from "src/core/types/loc";
import { IBundle } from "src/core/types/bundles";

const PageSetTypeOfTicket = () => {
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const { data, status } = useGetBundles();

  const { singleBundles, multipleBundles } = (data || []).reduce<{
    singleBundles: IBundle[];
    multipleBundles: IBundle[];
  }>(
    (acc, bundle) => {
      if (bundle.is_single) {
        acc.singleBundles.push(bundle);
      } else {
        acc.multipleBundles.push(bundle);
      }
      return acc;
    },
    { singleBundles: [], multipleBundles: [] },
  );

  return (
    <Wrapper>
      <InsideWrapper>
        {data && status === "success" && (
          <>
            <PanelRow panels={multipleBundles.sort((a, b) => a.position - b.position)} loc={loc} />
            <PanelRow panels={singleBundles.sort((a, b) => a.position - b.position)} loc={loc} />
          </>
        )}
        {status === "loading" && <CircularProgress color={"error"} />}
      </InsideWrapper>
    </Wrapper>
  );
};

export default PageSetTypeOfTicket;
