import { SelectedTicketReducer } from "src/core/redux/reducers/SelectedTicketReducer";
import { BundleReducer } from "src/core/redux/reducers/BundleReducer";
import { ReservationReducer } from "src/core/redux/reducers/ReservationReducer";
import { TimeSlotReducer } from "src/core/redux/reducers/TimeSlotReducer";
import { SelectedToursReducer } from "src/core/redux/reducers/SelectedToursReducer";
import { CapacityReducer } from "src/core/redux/reducers/CapacityReducer";
import { ReservationDataReducer } from "src/core/redux/reducers/ReservationDataReducer";
import { DiscountReducer } from "src/core/redux/reducers/DiscountReducer";

const reducers = {
  selectedTicket: SelectedTicketReducer,
  bundle: BundleReducer,
  reservationID: ReservationReducer,
  timeSlot: TimeSlotReducer,
  selectedTours: SelectedToursReducer,
  reservationData: ReservationDataReducer,
  capacity: CapacityReducer,
  discount: DiscountReducer,
};

export default reducers;
