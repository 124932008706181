import { ErrorsState } from "core/types/action";
import { TourName } from "src/core/types";

export const ticketTypes: Record<TourName, string> = {
  senior: "senior_entrance",
  family: "family_entrance",
  child: "children",
  baby: "children",
  adult: "adult_entrance",
  free: "free_ticket",
};

export const ticketDescriptions: Record<TourName, string> = {
  senior: "senior_condition",
  family: "family_condition",
  child: "children_type2",
  baby: "children_type1",
  adult: "adult_condition",
  free: "free_description",
};

export const defaultErrorsState: ErrorsState = {
  senior: false,
  family: false,
  child: false,
  baby: false,
  adult: false,
  free: false,
};
