import React, { SetStateAction } from "react";

import { Wrapper } from "src/components/molecules/RecapPayment/components/PaymentDetails/styledComponents";
import { RadioComponent } from "src/components/molecules/RecapPayment/components/RadioComponent";
import { CheckBoxComponents } from "src/components/molecules/RecapPayment/components/CheckBoxComponents";

import { RadioType } from "src/core/types/radioTypes";
import { CheckBoxTypes } from "src/core/types/checkBoxTypes";

export const PaymentDetails = ({
  radioState,
  setRadioState,
  checkBoxState,
  setCheckBoxState,
  isEnabled,
}: {
  radioState: RadioType;
  setRadioState: React.Dispatch<SetStateAction<RadioType>>;
  checkBoxState: CheckBoxTypes;
  setCheckBoxState: React.Dispatch<SetStateAction<CheckBoxTypes>>;
  isEnabled: boolean;
}) => {
  return (
    <Wrapper>
      <RadioComponent radioState={radioState} setRadioState={setRadioState} />
      <CheckBoxComponents
        checkBoxState={checkBoxState}
        setCheckBoxState={setCheckBoxState}
        isEnabled={isEnabled}
      />
    </Wrapper>
  );
};
