import { IPrioritySlot } from "core/types/bundles";

export const filterTimeByHalfHour = ({
  timeString,
  slots,
}: {
  timeString: string | undefined;
  slots: IPrioritySlot[];
}) => {
  if (!timeString) return slots;

  const targetHours = parseInt(timeString.slice(0, 2));
  const targetMinutes = parseInt(timeString.slice(3, 5));

  const adjustedHours = targetMinutes + 30 >= 60 ? targetHours + 1 : targetHours;
  const adjustedMinutes = (targetMinutes + 30) % 60;

  return slots.filter((slot: IPrioritySlot) => {
    const slotHours = parseInt(slot.time.slice(0, 2));
    const slotMinutes = parseInt(slot.time.slice(3, 5));

    return (
      slotHours > adjustedHours || (slotHours === adjustedHours && slotMinutes >= adjustedMinutes)
    );
  });
};
