import { CheckOutStep, Tab } from "src/core/types";
import { INextStep } from "src/core/types/setStep";

export const setToNextStep = ({
  tabs,
  panels,
  currentKey,
}: {
  tabs: Tab[];
  panels: CheckOutStep[];
  currentKey: number;
}): INextStep => {
  const newTabsState = tabs.map((tab: Tab) => {
    if (Number(tab.key) <= currentKey) {
      return { ...tab, isTabActive: false, isTabDone: true };
    }
    if (tab.key === String(currentKey + 1)) {
      return { ...tab, isTabActive: true, isTabDone: false };
    }
    return tab;
  });

  const newPanelsState = panels.map((panel: CheckOutStep) => {
    if (Number(panel.key) <= currentKey) {
      return { ...panel, isEnabled: false, isStepFinished: true, isPen: true };
    }
    if (panel.key === String(currentKey + 1)) {
      return { ...panel, isEnabled: true };
    }
    return panel;
  });
  return { newTabs: newTabsState, newPanels: newPanelsState };
};
