import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import TiketIcon from "../../../assets/ticket_color.svg";
import arrow from "../../../assets/separator_grey.svg";
import { useTranslation } from "react-i18next";
import { Tab } from "src/core/types";
import checkMark from "../../../assets/checkmark (1).svg";
import { styles } from "./styles";
import css_style from "./styles.module.css";
import separatorBlue from "../../../assets/separator_blue.svg";

interface PanelProps {
  tabs: Tab[];
  style: any;
}

export const Panel: React.FC<PanelProps> = ({ tabs, style }) => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1180);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 1180);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const renderIcon = (tab: Tab) => {
    if (tab.isTabActive) return <img src={tab.activeIcon} alt={tab.title} />;
    else if (tab.isTabDone)
      return (
        <>
          <img src={tab.doneIcon} alt={tab.title} />
          <img
            src={checkMark}
            style={{
              width: "50%",
              height: "50%",
              position: "absolute",
              top: "75%",
              left: "100%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
            }}
          />
        </>
      );
    else return <img src={tab.icon} alt={tab.title} />;
  };

  return (
    <Box style={style} className={css_style.box}>
      <Box className={css_style.title}>
        <img src={TiketIcon} style={{ marginRight: "15px", width: "24px" }} />
        <span className={css_style.titleText}>{t("tickets")}</span>
      </Box>
      <Box className={css_style.panelWrapper}>
        {tabs.map((tab, index) => {
          return (
            <React.Fragment key={tab.title}>
              {isMobile ? (
                <>
                  <Box className={css_style.panelTab}>{renderIcon(tab)}</Box>
                  {index < tabs.length - 1 && (
                    <>{tab.isTabDone ? <img src={separatorBlue} /> : <img src={arrow} />}</>
                  )}
                </>
              ) : (
                <>
                  <Box
                    className={`
                      ${css_style.panelTab} 
                      ${tab.isTabActive ? css_style.panelTabActive : ""} 
                      ${tab.isTabDone ? css_style.panelTabDone : ""}
                      `}
                  >
                    {renderIcon(tab)}
                    <span style={{ fontWeight: "600" }}>{t(tab.title)}</span>
                  </Box>
                  {index < tabs.length - 1 && (
                    <>{tab.isTabDone ? <img src={separatorBlue} /> : <img src={arrow} />}</>
                  )}
                </>
              )}
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
};
