import styled from "styled-components";

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10px;
    align-items: start;
  }
`;

export const NameAndStory = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const MoreInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ChangeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
`;

export const ChangeButtonText = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #e6264c;
`;

export const BundleName = styled.span`
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #051c4766;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const Description = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #051c4766;
`;

export const BlockWithIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const BlockWithIconText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #051c4766;
`;

export const SlotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 13px;
  margin: 0;
`;
