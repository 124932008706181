import { useMutation } from "react-query";
import axios from "axios";
import { MANUAL_PAYMENT_API_ROUTE } from "../endpoints";
import { ManualPayment, ManualPaymentResponse } from "../types/manual_payment";

export const usePostManualPayment = ({
  onSuccess,
  onError,
}: {
  onSuccess: (data: ManualPaymentResponse) => void;
  onError: () => void;
}) => {
  return useMutation(
    (submitData: ManualPayment) => {
      return axios.post(`${window.BASE_API_URL}/${MANUAL_PAYMENT_API_ROUTE}`, submitData);
    },
    {
      onSuccess: (data: any) => onSuccess(data.data as ManualPaymentResponse),
      onError: () => onError(),
    },
  );
};
