import { CheckOutStep, INextStep, Tab } from "src/core/types";

export const setStepToPen = ({
  tabs,
  panels,
  currentKey,
}: {
  tabs: Tab[];
  panels: CheckOutStep[];
  currentKey: number;
}): INextStep => {
  const newTabsState = tabs.map((tab: Tab) => {
    if (Number(tab.key) < currentKey) {
      return { ...tab, isTabActive: false, isTabDone: true };
    }
    if (tab.key === String(currentKey)) {
      return { ...tab, isTabActive: true, isTabDone: false };
    }
    return { ...tab, isTabActive: false, isTabDone: false };
  });
  const newPanelsState = panels.map((panel: CheckOutStep) => {
    if (Number(panel.key) < currentKey) {
      return { ...panel, isEnabled: false, isStepFinished: true };
    }
    if (panel.key === String(currentKey)) {
      return { ...panel, isEnabled: true, isStepFinished: false, isActiveTab: true };
    }
    return { ...panel, isEnabled: false, isStepFinished: false, isPen: false };
  });

  return { newTabs: newTabsState, newPanels: newPanelsState };
};
