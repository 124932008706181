import { Checkbox, CircularProgress, Typography } from "@mui/material";
import { IPrioritySlot, ISlot, ISlotData, SlotKey } from "core/types/bundles";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectedBundle from "src/components/molecules/BundleComponent/components/SelectedBundle";
import {
  CheckboxTitle,
  CheckboxWrapper,
  Filters,
  HeaderItem,
  HeaderItemText,
  HeaderWrapper,
  LoadingWrapper,
  NoTicketsText,
  NoTicketsWrapper,
  TicketHeader,
  TicketWrapper,
  Title,
  Wrapper,
} from "src/components/molecules/BundleComponent/components/styledComponents";
import TableTicket from "src/components/molecules/BundleComponent/components/TableTicket";
import { TableTicketMobile } from "src/components/molecules/BundleComponent/components/TableTicketMobile";
//TODO: refactor -> divide to bigger components
import PreferButtons from "src/components/molecules/PreferButtons";
import { Loc } from "src/core/types/loc";
import { TicketFilterPayload } from "src/core/types/reduxTypes";
import { filterTimeByHalfHour } from "src/core/utils/filterSlotsByHalfHour";
import { filterTimeSlots } from "src/core/utils/filterTimeSlots";
import { guidedHeader, unGuidedHeader } from "src/core/utils/ticketsHeader";

const fadeInVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};

const BundleComponent = ({
  bundle,
  loc,
  tour,
  isSelected,
  selectSlot,
  selectedSlot,
  deleteSlot,
  is_alone,
  isAvailable,
  parentSlot,
}: {
  bundle: ISlotData | undefined;
  loc: Loc;
  tour: SlotKey;
  isSelected: boolean;
  selectSlot: (slot: ISlot, tour: SlotKey) => void;
  selectedSlot: IPrioritySlot;
  deleteSlot: (tour: SlotKey) => void;
  is_alone: boolean;
  isAvailable: boolean;
  parentSlot: IPrioritySlot | undefined;
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const { t } = useTranslation();
  const headerArray = bundle?.can_have_exposition ? guidedHeader : unGuidedHeader;
  const [checkBoxState, setCheckBoxState] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<TicketFilterPayload>("everytime");
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const filterByGuide = (slots: IPrioritySlot[]) => {
    if (checkBoxState) {
      return slots.filter((slot: ISlot) => slot.is_guided);
    }
    return slots;
  };

  const shouldShowWarning = (slot: IPrioritySlot) => {
    if (!slot || !parentSlot) return false;

    const [slotHours, slotMinutes] = slot.time.split(":").map(Number);
    const [parentHours, parentMinutes] = parentSlot.time.split(":").map(Number);

    const slotTotalMinutes = slotHours * 60 + slotMinutes;
    const parentTotalMinutes = parentHours * 60 + parentMinutes;

    const timeDifference = Math.abs(slotTotalMinutes - parentTotalMinutes);

    return timeDifference <= 30;
  };

  const hanldeControlTooltip = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <AnimatePresence mode="wait">
      {isSelected ? (
        <motion.div
          key="selected"
          layout
          variants={fadeInVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ duration: 0.3 }}
          style={{ width: "100%" }}
        >
          <SelectedBundle
            slot={selectedSlot}
            tour={tour}
            is_alone={is_alone}
            bundle={bundle || undefined}
            deleteSlot={deleteSlot}
            loc={loc}
          />
        </motion.div>
      ) : (
        <motion.div
          key="unselected"
          layout
          variants={fadeInVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ duration: 0.3 }}
          style={{ width: "100%" }}
        >
          <Wrapper isEmpty={!bundle || bundle?.slots.length === 0}>
            {!bundle ? (
              <LoadingWrapper>
                <CircularProgress color={"error"} />
              </LoadingWrapper>
            ) : (
              <>
                <HeaderWrapper>
                  <Title>{bundle.title[loc] || bundle.title.cs || ""}</Title>
                </HeaderWrapper>
                {bundle.slots.length !== 0 && (
                  <Filters>
                    <PreferButtons
                      setSelectedOption={setSelectedFilter}
                      selectedOption={selectedFilter}
                    />
                    {bundle && bundle.can_be_guided && (
                      <CheckboxWrapper>
                        <Checkbox
                          onChange={(_, state) => setCheckBoxState(state)}
                          checked={checkBoxState}
                        />
                        <CheckboxTitle>{t("guide_tour")}</CheckboxTitle>
                      </CheckboxWrapper>
                    )}
                  </Filters>
                )}
                {filterByGuide(filterTimeSlots({ slots: bundle.slots, filter: selectedFilter }))
                  .length > 0 ? (
                  <>
                    {!isMobile && (
                      <TicketHeader>
                        {headerArray.map((element) => {
                          if (!bundle.can_have_exposition && element.id === "story_name") {
                            return null;
                          }
                          return (
                            <HeaderItem key={element.id}>
                              <HeaderItemText>{element.label}</HeaderItemText>
                            </HeaderItem>
                          );
                        })}
                      </TicketHeader>
                    )}

                    <TicketWrapper onClick={hanldeControlTooltip}>
                      {bundle &&
                        filterTimeByHalfHour({
                          timeString: parentSlot?.time,
                          slots: filterByGuide(
                            filterTimeSlots({ slots: bundle.slots, filter: selectedFilter }),
                          ),
                        }).map((slot: IPrioritySlot, i: number) => {
                          return (
                            <TableTicket
                              key={slot.id}
                              slot={slot}
                              bundle={bundle}
                              tour={tour}
                              loc={loc}
                              warning={!!parentSlot && i === 0 && shouldShowWarning(slot)}
                              selectSlot={selectSlot}
                              isAllowed={isAvailable}
                              isTooltipOpen={isTooltipOpen}
                            />
                          );
                        })}
                    </TicketWrapper>
                  </>
                ) : (
                  bundle.slots.length === 0 && (
                    <NoTicketsWrapper>
                      <NoTicketsText>
                        <Typography
                          variant="body2"
                          dangerouslySetInnerHTML={{ __html: t("no_available_timeslots") }}
                        />
                      </NoTicketsText>
                    </NoTicketsWrapper>
                  )
                )}
              </>
            )}
          </Wrapper>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BundleComponent;
