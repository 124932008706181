import React, { useEffect, useState } from "react";
import { CheckOutStep, Discount, Tab } from "src/core/types";
import colorPayment from "src/assets/payment_color.svg";

import {
  DiscountWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from "src/components/molecules/RecapPayment/styledComponents";
import { ReceiptComponent } from "src/components/molecules/RecapPayment/components/ReceiptComponent";
import { PaymentDetails } from "src/components/molecules/RecapPayment/components/PaymentDetails";
import PaymentDiscountComponent from "src/components/molecules/PaymentDiscountComponent";
import { PaymentConfirmation } from "src/components/molecules/RecapPayment/components/PaymentConfirmation";

import { setToNextStep } from "src/core/utils";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { RadioType } from "src/core/types/radioTypes";
import { CheckBoxTypes } from "src/core/types/checkBoxTypes";
import { RootState } from "src/core/redux/store";
import { SlotKey } from "src/core/types/bundles";
import { checkBoxesDiscount, checkBoxValues } from "src/core/lists/checkBoxesDiscount";
import { resetDiscount, setDiscount } from "src/core/redux/actions/discountActions";
import { usePostManualPayment } from "src/core/queries/usePostManualPayment";
import { ManualPayment } from "src/core/types/manual_payment";

interface RecapPaymentProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  step: CheckOutStep;
  panels: CheckOutStep[];
  tabs: Tab[];
  nextStep: string;
  shouldBePensShown: boolean;
}

export const RecapPayment = ({ updateCheckOutState, step, panels, tabs }: RecapPaymentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedBundle = useSelector((state: RootState) => state.bundle);
  const selectedTickets = useSelector((state: RootState) => state.reservationData?.selectedTickets);
  const globTickets = useSelector((state: RootState) => state.reservationData?.tickets);
  const globDiscount = useSelector((state: RootState) => state.discount);

  const reservationId = useSelector((state: RootState) => state.reservationID?.reservationID);
  const discount = useSelector((state: RootState) => state.discount?.label) || "";

  const token = localStorage.getItem("token");

  const [radioState, setRadioState] = useState<RadioType>("card");
  const [checkBoxState, setCheckBoxState] = useState<CheckBoxTypes>(null);

  const [discountInfo, setDiscountInfo] = useState<Discount>({} as Discount);

  const onSuccess = () => {
    const { newTabs, newPanels } = setToNextStep({
      tabs: tabs,
      panels: panels,
      currentKey: Number(step.key),
    });
    updateCheckOutState(newTabs, newPanels);
  };
  const onError = () => {};

  const { mutate } = usePostManualPayment({ onSuccess: onSuccess, onError: onError });

  const invokeConfirmation = () => {
    const mutateData: ManualPayment = {
      token: token || "",
      payment_method: "card",
      discount: discount,
      reservation: reservationId || "",
    };
    void mutate(mutateData);
  };

  const getTourPrice = (tour: SlotKey): number => {
    let total = 0;
    if (selectedTickets) {
      selectedTickets[tour].forEach((ticket) => {
        const searchedTicket = globTickets?.find((globTicket) => globTicket.name === ticket.name);
        if (searchedTicket) {
          total += ticket.amount * Number(searchedTicket.price);
        }
      });
    }
    return total;
  };

  useEffect(() => {
    if (!checkBoxState) {
      dispatch(resetDiscount());
    } else {
      const discountLabel = checkBoxesDiscount.find((_discount) => _discount.id === checkBoxState);
      dispatch(
        setDiscount({
          discount_type: "percent",
          type: "check",
          amount: checkBoxValues[checkBoxState],
          label: discountLabel?.id || "",
        }),
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkBoxState]);

  return step.isEnabled ? (
    <Wrapper>
      <TitleWrapper>
        <img src={colorPayment} alt="colorPayment" />
        <Title>{t("recap_and_payment")}</Title>
      </TitleWrapper>
      <ReceiptComponent />
      <PaymentDetails
        isEnabled={globDiscount?.type !== "input"}
        radioState={radioState}
        setRadioState={setRadioState}
        checkBoxState={checkBoxState}
        setCheckBoxState={setCheckBoxState}
      />
      <DiscountWrapper>
        <PaymentDiscountComponent
          bundle={selectedBundle}
          getTourPrice={getTourPrice}
          isDisabled={!!checkBoxState}
        />
      </DiscountWrapper>
      <PaymentConfirmation onConfirm={invokeConfirmation} />
    </Wrapper>
  ) : (
    <div></div>
  );
};
