import { PaymentDiscount } from "src/core/types/discounts";

export const setDiscount = (data: PaymentDiscount) => ({
  type: "SET_DISCOUNT_G",
  payload: data,
});

export const resetDiscount = () => ({
  type: "RESET_DISCOUNT",
});
