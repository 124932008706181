import React, { useEffect, useState } from "react";
import {
  CheckBoxLabel,
  CheckBoxWrapper,
  Wrapper,
} from "src/components/molecules/RecapPayment/components/PaymentConfirmation/styledComponents";
import NextStepButton from "src/components/atoms/NextStepButton";

import { Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ConfirmationPen } from "src/components/molecules/RecapPayment/components/ConfirmationPen";

export const PaymentConfirmation = ({ onConfirm }: { onConfirm: () => void }) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [confirmComponentState, setConfirmComponentState] = useState<boolean>(false);
  const [isPayed, setIsPayed] = useState<boolean>(false);

  const invokeConfirmation = () => {
    setIsPayed(true);
    setConfirmComponentState(false);
  };

  const showConfirmationComponent = () => {
    setConfirmComponentState(true);
  };

  const onDecline = () => {
    setConfirmComponentState(false);
    setIsChecked(false);
    setIsPayed(false);
  };

  useEffect(() => {
    if (!isChecked) {
      setIsPayed(false);
    }
  }, [isChecked]);
  return (
    <Wrapper>
      {!confirmComponentState ? (
        <>
          <CheckBoxWrapper>
            <Checkbox checked={isChecked} onChange={() => setIsChecked((prev) => !prev)}></Checkbox>
            <CheckBoxLabel>{t("customer_have_paid_by")}</CheckBoxLabel>
          </CheckBoxWrapper>
          <NextStepButton
            text={t("go_to_bill")}
            handler={!isPayed ? showConfirmationComponent : onConfirm}
            isActive={isChecked}
          />
        </>
      ) : (
        <ConfirmationPen onSubmit={invokeConfirmation} onDecline={onDecline} />
      )}
    </Wrapper>
  );
};
