import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Logo } from "../../atoms/Logo";
import logOut from "../../../assets/logout_cerveny.svg";
import { clearFormValues, useFormValuesContext } from "src/core/context";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "src/core/routing";
import foto from "../../../assets/person.svg";
import styles from "./styles.module.css";

const HomeHeader: React.FC = () => {
  const userName = localStorage.getItem("name");
  const userEmail = localStorage.getItem("email");
  const { formValues, setFormValues } = useFormValuesContext();
  const reservationId = formValues.reservation || "";
  const navigate = useNavigate();

  const handleClick = () => {
    if (!reservationId) {
      navigate(LOGIN_ROUTE);
      setFormValues(clearFormValues);
      localStorage.clear();
      return;
    }
    setFormValues(clearFormValues);
    localStorage.clear();
    navigate(LOGIN_ROUTE);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box className={styles.container}>
      <AppBar color="inherit" position="fixed" style={{ width: "100%", left: 0, right: 0 }}>
        <Toolbar
          style={{
            margin: "auto",
            flexWrap: "nowrap",
            width: "65vw",
            maxWidth: "1360px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Logo />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={foto} style={{ width: "60px", marginRight: "15px" }} />
            <div style={{ marginRight: "25px", cursor: "pointer" }}>
              <Typography
                color={"rgba(6, 39, 98, 1)"}
                fontWeight={400}
                fontSize={"20px"}
                whiteSpace={"nowrap"}
              >
                {userName}
              </Typography>
              <Typography
                color={"rgba(6, 39, 98, 1)"}
                fontWeight={400}
                fontSize={"20px"}
                whiteSpace={"nowrap"}
              >
                {userEmail}
              </Typography>
            </div>
            <img src={logOut} style={{ cursor: "pointer" }} onClick={handleClick} />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default HomeHeader;
