import React, { SetStateAction } from "react";
import {
  CheckBoxComponentWrapper,
  CheckBoxLabel,
  CheckBoxTitle,
  CheckBoxWrapper,
  Wrapper,
} from "src/components/molecules/RecapPayment/components/CheckBoxComponents/styledComponents";
import { useTranslation } from "react-i18next";

import { CheckBoxTypes } from "src/core/types/checkBoxTypes";
import { checkBoxesDiscount } from "src/core/lists/checkBoxesDiscount";
import Checkbox from "@mui/material/Checkbox";

export const CheckBoxComponents = ({
  checkBoxState,
  setCheckBoxState,
  isEnabled,
}: {
  checkBoxState: CheckBoxTypes;
  setCheckBoxState: React.Dispatch<SetStateAction<CheckBoxTypes>>;
  isEnabled: boolean;
}) => {
  const { t } = useTranslation();

  const controlCheckBoxState = (value: CheckBoxTypes) => {
    if (checkBoxState === value) {
      return setCheckBoxState(null);
    }
    return setCheckBoxState(value);
  };

  return (
    <Wrapper>
      <CheckBoxTitle>{t("customer_discount")}</CheckBoxTitle>
      <CheckBoxWrapper>
        {checkBoxesDiscount.map((discountType) => (
          <CheckBoxComponentWrapper key={discountType.id}>
            <Checkbox
              disabled={!isEnabled}
              checked={checkBoxState === discountType.id}
              onChange={() => controlCheckBoxState(discountType.id)}
            />
            <CheckBoxLabel>{discountType.label}</CheckBoxLabel>
          </CheckBoxComponentWrapper>
        ))}
      </CheckBoxWrapper>
    </Wrapper>
  );
};
