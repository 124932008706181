import styled from "styled-components";

interface IWrapper {
  isEmpty: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  position: relative;
  max-width: 902px;
  height: ${(props) => (props.isEmpty ? "278px" : "548px")};
  border-radius: 10px;
  padding: 16px;
  border: 1px solid #e1e1e1;
  background: #ffffff;

  @media (max-width: 768px) {
    max-width: 400px;
    padding: 3px;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-top: 16px;
    gap: 10px;
  }
`;

export const Image = styled.img`
  width: 183px;
  height: 102px;
  border-radius: 10px;
`;

export const Title = styled.span`
  font-family: Montserrat, serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #062762;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  overflow: auto;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 5px;
`;

export const CheckboxTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #000000;
`;

export const TicketWrapper = styled.div`
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  gap: 10px;
  border-bottom: 1px solid #e1e1e1;
`;

export const NoTicketsWrapper = styled.div`
  width: 100%;
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoTicketsText = styled.span`
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #062762;
`;

export const TicketHeader = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #000000;
  gap: 10px;
  padding-left: 20px;
`;

export const HeaderItem = styled.div`
  width: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const HeaderItemText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062762;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
