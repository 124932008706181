import { Box, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import {
  PanelWrapper,
  TitleWrapper,
  Wrapper,
} from "src/components/pages/PageSetTypeOfTicket/components/PanelRow/styledComponent";
import Panel from "src/components/pages/PageSetTypeOfTicket/components/Panel";
import { IBundle } from "core/types/bundles";
import { Loc } from "src/core/types/loc";

import styles from "./styles.module.css";

interface PanelRowProps {
  title?: string;
  panels: IBundle[];
  loc: Loc;
}

const PanelRow: React.FC<PanelRowProps> = ({ title, panels, loc }) => {
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);

  return (
    <Wrapper>
      {title && (
        <TitleWrapper>
          <Typography
            variant="h2"
            color="#062762"
            fontWeight="bold"
            fontSize={{ xs: "20px", md: "24px" }}
          >
            {title}
          </Typography>
        </TitleWrapper>
      )}
      <PanelWrapper>
        <Box className={styles.container} {...events} ref={ref}>
          {panels.map((panel: IBundle) => (
            <Panel key={panel.name} bundle={panel} loc={loc} />
          ))}
        </Box>
      </PanelWrapper>
    </Wrapper>
  );
};

export default PanelRow;
