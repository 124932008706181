import { CheckBoxTypes } from "src/core/types/checkBoxTypes";

export const checkBoxesDiscount: { id: CheckBoxTypes; label: string }[] = [
  {
    id: "brnopass30",
    label: "Brnopass30",
  },
  {
    id: "skoly10",
    label: "Školy10",
  },
];

export const checkBoxValues: Record<string, number> = {
  skoly10: 10,
  brnopass30: 30,
};
